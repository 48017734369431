import {makeStyles, Tooltip} from '@material-ui/core';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import React, {useState} from 'react';
import {Campaign} from '../../../../model/campaign/Campaign';
import {HeartFillIcon} from './CampaignHeader';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {SuccessToast} from '../../../../utils/toasters';
import {PlannerStatusEnum} from '../../../../enum/PlannerStatusEnum';
import { BASE_URL_PUBLIC } from '../../../../utils/endpoints';

export interface ICampaignHeaderButtonsProps {
    campaign?: Campaign | Partial<Campaign>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    addToShortcuts?: (id?: number) => void;
}

export function CampaignHeaderButtons({campaign, setShow, addToShortcuts}: ICampaignHeaderButtonsProps) {
    const [hoveredIcons, setHoveredIcons] = useState({
        change: false,
        heart: false,
        settings: false,
        leave: false,
    });
    const {t} = useTranslation();
    const history = useHistory();
    const useStyles = makeStyles({
        tooltip: {
            backgroundColor: 'dark',
        },
    });

    const classes = useStyles();

    const exitCampaign = () => {
        history.push(InternalRoutes.BRAND_DASHBOARD);
    };

    const handleHoverEnter = (name: string) => {
        setHoveredIcons(prev => ({...prev, [name]: true}));
    };
    const handleHoverLeave = (name: string) => {
        setHoveredIcons(prev => ({...prev, [name]: false}));
    };

    const handleEdit = (campaignId: number, briefingStep: number, isFinished = false) => {
        if (isFinished) {
            history.push(`/brand/create/campaign/${campaignId}/overview`);
        } else {
            history.push(`/brand/create/campaign/${campaignId}/step${briefingStep}`);
        }
    };

    const copyCampaign = () => {
        SuccessToast(t('general.button.copied'));
        navigator.clipboard.writeText(`${BASE_URL_PUBLIC}/campaign/${campaign?.uuid}`);
    };


    return (
        <>
            <div
                onClick={() => setShow(prevState => !prevState)}
                className="icon-container mr-2 cursor-pointer bg-white"
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    title={t('general.changeCampaign')}

                    arrow
                >
                    <div
                        onMouseEnter={() => handleHoverEnter('change')}
                        onMouseLeave={() => handleHoverLeave('change')}
                        className={`d-flex h-100 align-items-center justify-content-center wrapper cursor-pointer default-radius ${
                            hoveredIcons?.change && 'w-100 '
                        } p-2  `}
                    >
                        <i className={`fi fi-rr-replace ${hoveredIcons?.change ? 'text-primary' : ''} font-18
                                            d-flex align-items-center justify-content-center`}/>
                    </div>
                </Tooltip>
            </div>
            {addToShortcuts &&
                <div onClick={() => addToShortcuts(campaign?.id ?? 0)}
                     className={`icon-container mr-2 cursor-pointer bg-white ${
                         campaign?.isFavorite ? 'active' : ''
                     }`}
                     onMouseEnter={() => handleHoverEnter('heart')}
                     onMouseLeave={() => handleHoverLeave('heart')}
                >
                    <Tooltip
                        classes={{tooltip: classes.tooltip}}
                        title={
                            campaign?.isFavorite
                                ? t('general.removeShortcuts')
                                : t('general.addToShortcuts')
                        }
                        arrow
                    >
                        <div
                        >
                            {campaign?.isFavorite ?
                                <HeartFillIcon/> :
                                <i className={`fi fi-rr-heart d-flex font-18 ${hoveredIcons?.heart ?
                                    'text-info' : ''}`}></i>}
                        </div>

                    </Tooltip>
                </div>
            }
            <div
                onClick={() => handleEdit(campaign?.id ?? 0, Number(campaign?.briefingStep ?? 1), campaign?.isBriefingComplete)}
                onMouseEnter={() => handleHoverEnter('settings')}
                onMouseLeave={() => handleHoverLeave('settings')}
                className="icon-container mr-2 cursor-pointer bg-white">
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    title={t('general.editBriefing')}
                    arrow
                >
                    <div
                    >
                        <i className={`fi fi-rr-file-edit d-flex font-18
                                            ${hoveredIcons?.settings ? 'text-warning' : ''}`}></i>
                    </div>
                </Tooltip>
            </div>
            <div
                onClick={exitCampaign}
                onMouseEnter={() => handleHoverEnter('leave')}
                onMouseLeave={() => handleHoverLeave('leave')}
                className="icon-container mr-2 cursor-pointer bg-white"
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    title={t('general.exitCampaign')}

                    arrow
                >
                    <div
                    >
                        <i className={`fi fi-rr-exit d-flex font-18
                                            ${hoveredIcons?.leave ? 'text-danger' : ''}`}></i>
                    </div>
                </Tooltip>
            </div>
            {campaign?.approvalStatus === PlannerStatusEnum.APPROVED && (
                <div
                    onClick={copyCampaign}
                    onMouseEnter={() => handleHoverEnter('leave')}
                    onMouseLeave={() => handleHoverLeave('leave')}
                    className="icon-container cursor-pointer bg-white"
                >
                    <Tooltip
                        classes={{tooltip: classes.tooltip}}
                        title={t('brand.campaign.briefing.copy_public_briefing')}
                        arrow
                    >
                        <div>
                            <i className={`fi fi-rr-link d-flex font-18
                                                ${hoveredIcons?.leave ? 'text-danger' : ''}`}></i>
                        </div>
                    </Tooltip>
                </div>
            )}
        </>
    )
}