import React, {useEffect, useState} from 'react';
import {Button, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiErrorCircle} from 'react-icons/bi';
import {useSelector} from 'react-redux';
import {PackagesEnum} from '../../../../enum/PackageEnum';
import {UserRoles} from '../../../../enum/UserRoles';
import {BackButton} from '../../../../shared/components/BackButton';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {UserAndRolesBasicPack} from '../../packages/components/UserAndRolesBasicPack';
import {ICompanyUser} from '../dto/ICompanyUser';
import {UserService} from '../service';
import {UserAndRolesCard} from './UserAndRolesCard';
import UserAndRolesModal from './UserAndRolesModal';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';

function UserAndRoles() {
    const {t} = useTranslation();
    const {user: {userType, company, id: loggedUserId}} = useSelector((reduxState: IAllStates) => reduxState.auth);
    // const isBasic = company?.package?.name === PackagesEnum.BASIC;
    // const nbEmployees = company?.package?.nbEmployees ?? 1;


    const [showCreateNewUserModal, setShowCreateNewUserModal] = useState(false);
    const [usersList, setUsersList] = useState<ICompanyUser[]>([]);
    const [updateList, setUpdateList] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<ICompanyUser>();
    const [disabledUsers, setDisabledUsers] = useState(false);
    
    useEffect(() => {
        const getUsersFunction = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles)  ? UserService.getAgencyCompanyUsers : UserService.getCompanyUsers;
    
        getUsersFunction()
            .then((data) => {
                const filteredUsers = data.filter((user) => +user?.id! !== +loggedUserId);
                if (filteredUsers.every((user) => user.isEnabled === true) && !!disabledUsers) {
                    setDisabledUsers(!disabledUsers);
                }
                setUsersList(filteredUsers);
            })
            .catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [updateList]);

    function disableUser(userId: string) {
        const disableUserFunction = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles) ? UserService.disableAgencyUser : UserService.disableCompanyUser;
    
        disableUserFunction(userId)
            .then((data) => {
                SuccessToast(t('general.toasts.successUpdated'));
                setUpdateList(prevState => !prevState);
            })
            .catch(error => ErrorToast(error));
    }
    

    const deleteUser = () => {
        UserService.deleteCompanyUser(selectedUser?.id ?? '').then((data) => {
            setUpdateList(prevState => !prevState);
            SuccessToast(t('general.toasts.successRemovedUser'));
        }).catch(error => ErrorToast(error));
    };

    const maximumEmployees = company?.package?.nbEmployees ? company?.package?.nbEmployees : 10;
    const isMaximumUsersReached = usersList?.length >= maximumEmployees;
    const handleShowCreateUserModal = () => setShowCreateNewUserModal(true);
    const handleCloseCreateUserModal = () => {
        setShowCreateNewUserModal(false);
        setSelectedUser(undefined);
    };
    const deleteUserAction = (companyUser?: ICompanyUser) => {
        setShowConfirmModal(true);
        setSelectedUser(companyUser);
    };
    const closeConfirmModalAction = () => {
        setSelectedUser(undefined);
        setShowConfirmModal(false);
    };

    const editUser = (companyUser?: ICompanyUser) => {
        setSelectedUser(companyUser);
        setShowCreateNewUserModal(true);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-md-center align-items-start mb-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <BackButton/>
                    <div className="d-flex flex-column">
                        <h5>{t('brand.account.users_and_roles')}</h5>
                        <span className="text-muted">{t('brand.account.users_and_roles_desc')}</span>
                    </div>
                </div>
                <div className="d-flex align-items-center mt-3 mt-md-0">
                    <div className="d-flex flex-column align-items-end mr-3">
                        <strong dangerouslySetInnerHTML={{__html: t('general.support')}}></strong>
                        <p className="font-12 mb-0 text-end">
                            {t('general.support_question')}
                        </p>
                        <a href="mailto:support@influencevision.com">
                            <p className="font-12 text-end mb-0">
                                support@influencevision.com
                            </p>
                        </a>
                    </div>
                    <div className="user-img d-none d-md-block">
                        <img src="/tempAssets/support.jpg" alt="Support profile img"/>
                    </div>
                </div>
            </div>
            {(!company?.package?.teamCollaboration && UserRoles.BRAND_MASTER === userType) ? (
                <UserAndRolesBasicPack/>
            ) : (
                <div className="card ">
                    <div className="card-body px-2">
                        <div className="mb-3 d-flex align-items-center justify-content-between px-3">
                            <div>
                                {!disabledUsers && (
                                    <>
                                        <h5 className="text-primary mb-0">{Math.max(0, usersList.filter((user) => user?.isEnabled !== disabledUsers)?.length)}/{maximumEmployees}</h5>
                                        <span>{t('general.button.teamMember')}</span>
                                    </>
                                )}
                            </div>
                            <div>
                                {usersList.some((user) => user.isEnabled === false) && (
                                    <Button variant='outline-primary' className='mr-2' onClick={()=> setDisabledUsers((prev) => !prev)}>
                                        {disabledUsers ? t('general.button.seeActive') : t('general.button.seeInactive')}
                                    </Button>
                                )}
                             
                                {!disabledUsers && (
                                    <Button disabled={isMaximumUsersReached} onClick={handleShowCreateUserModal}>
                                        {t('general.button.addTeamMember')}
                                    </Button>
                                )}
                                {isMaximumUsersReached && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="maximum-users-reached">
                                                <span>
                                                    {t('brand.package.maximumUsersReached')}
                                                </span>
                                            </Tooltip>
                                        }
                                    >
                                        <BiErrorCircle size={20} className="text-danger ml-2 cursor-pointer"/>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </div>
                        <Row>
                            {!!usersList.filter((user) => user?.isEnabled !== disabledUsers).length ? usersList.filter((user) => user?.isEnabled !== disabledUsers)?.map((user) => {
                                return (
                                    <UserAndRolesCard
                                    key={user?.id}
                                    companyUser={user}
                                    disableUser={disableUser}
                                    deleteUserAction={deleteUserAction}
                                    editUser={editUser}
                                />)
                            }) : (
                                <EmptyState/>
                            )}
                        </Row>
                    </div>
                </div>
            )}
            <UserAndRolesModal
                show={showCreateNewUserModal}
                closeModal={handleCloseCreateUserModal}
                companyUser={selectedUser}
                updateList={setUpdateList}
            />
            <ConfirmationModal title={t('brand.account.delete_user_title')}
                               description={t('brand.account.delete_user_desc')}
                               action={deleteUser}
                               show={showConfirmModal}
                               closeModal={closeConfirmModalAction}/>
        </>
    );
}

export default UserAndRoles;
