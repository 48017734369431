import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CampaignFilter} from '../../../campaign/component/CampaignFilter';
import {IMyCampaignsFilterProps} from '../../../campaign/dto/IMyCampaigns';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import EmptyState from '../../../../../shared/components/placeholder/EmptyState';
import { Card } from 'react-bootstrap';
import CardViewOffer from './CardViewOffer';
import {IOfferWithCampaign} from '../../../profile/profile-view';
import TableViewOffer from './TableViewOffer';

interface IOfferList {
    offers?: IOfferWithCampaign[];
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    isAgency?: boolean;
}

const OfferList = ({offers, setFilterParams, filterParams, isAgency}: IOfferList) => {
    const {t} = useTranslation();
    const [isCardView, setIsCardView] = useState(true);
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);

    return (
        <>
            {selectedInfluencer?.id ?
                <h4>{t('influencer.dashboard.campaigns_from', {firstName: selectedInfluencer?.firstName})}</h4> :
                <h4>{t('influencer.dashboard.yourCampaigns')}</h4>
            }
            <div className="my-1">
                <CampaignFilter 
                    searchKey="title" 
                    isCardView={isCardView} 
                    setIsCardView={setIsCardView}
                    filterParams={filterParams}
                    showCampaignStatus={false}
                    setFilterParams={setFilterParams}
                />
            </div>
            {!!offers?.length ? (
                <div className="mb-5">
                    {isCardView ? <CardViewOffer offers={offers}/> : <TableViewOffer offers={offers}/>}
                </div>
            ) : (
                <Card className="mt-3">
                    <Card.Body>
                        <EmptyState
                            navigateTo={isAgency ? '/agency/explore' : '/influencer/explore'}
                            btnText={t('general.tabs.explore')}/>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default OfferList;
