import Picker from 'emoji-picker-react';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IoIosAttach} from 'react-icons/io';
import {BsEmojiSmile} from 'react-icons/bs';
import {User} from '../../../../../../model/user/User';
import MentionInputField from '../../../../../../shared/components/message/MentionInputField';
import {ErrorToast, WarningToast} from '../../../../../../utils/toasters';
import {IMessageNew} from '../../dto/IMessage';
import {MessageService} from '../../service';
import {changeFileHandler, hasAccessRights} from '../../../../../../shared/functions/Functions';
import {AccessRightEnum} from '../../../../../../enum/AccessRightEnum';

export interface IMainChatInputFieldsProps {
    getMessagesPolling?: (selectedChatId?: number) => void;
    selectedChatId: number;
    isChatBubble?: boolean;
    setTriggerUpdate?: React.Dispatch<SetStateAction<boolean>>;
    isOfferComment?: boolean;
    isContractComment?: boolean;
    offerId?: number;
}

export const MainChatInputFields = ({
                                        getMessagesPolling,
                                        selectedChatId,
                                        setTriggerUpdate,
                                        isChatBubble,
                                        isOfferComment = false,
                                        isContractComment,
                                        offerId,
                                    }: IMainChatInputFieldsProps) => {
    const [newData, setNewData] = useState<IMessageNew>();
    const {t} = useTranslation();
    const [emoji, setEmoji] = useState((isOfferComment || isContractComment) ? (window.sessionStorage.getItem(`offerComment-${offerId}`) ?? '') : '');
    const [showPicker, setShowPicker] = useState(false);
    const [possibleMentions, setPossibleMentions] = useState<User[]>([]);

    const onEmojiClick = (emoji: string) => {
        setEmoji((prevInput) => prevInput + emoji);
        setShowPicker(false);
    };
    const changeMessageHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {value} = event.target;
        setEmoji(value);
        (isContractComment || isOfferComment) && window.sessionStorage.setItem(`offerComment-${offerId}`, value);
    };

    const sendMessage = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<SVGElement, MouseEvent>) => {
        event.preventDefault();
        if (!emoji?.length && !newData?.file?.length) {
            return WarningToast(t('general.toasts.warnEmptyMessage'));
        }
        const formData = new FormData();
        if (newData?.file) {
            for (const file in newData?.file) {
                formData.append('files', newData?.file[file]);
            }
        }
        if (isOfferComment) {
            formData.append('body', JSON.stringify({
                body: emoji?.trim().replaceAll('\n', '</br>'),
                offerId: selectedChatId,
            }));
            MessageService.addOfferComments(formData as any, isContractComment).then(response => {
                // getMessagesPolling && getMessagesPolling(selectedChatId);
                // setSelectedChatContent && setSelectedChatContent(prev => ([response, ...prev]))
                setNewData({
                    message: '',
                    file: undefined,
                });
                setEmoji('');
                window.sessionStorage.removeItem(`offerComment-${offerId}`);
                setTriggerUpdate && setTriggerUpdate(prev => !prev);
            }).catch(error => ErrorToast(error))
        } else {
            formData.append('body', JSON.stringify({
                message: emoji?.trim().replaceAll('\n', '</br>'),
                threadId: selectedChatId,
            }));
            MessageService.sendNewInboxMessage(formData as any).then(response => {
                getMessagesPolling && getMessagesPolling(selectedChatId);
                // setSelectedChatContent && setSelectedChatContent(prev => ([response, ...prev]))
                setNewData({
                    message: '',
                    file: undefined,
                });
                setEmoji('');
                setTriggerUpdate && setTriggerUpdate(prev => !prev);
            }).catch(error => ErrorToast(error));
        }
        ;
    }

    // const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    //     if (event.key === 'Enter' && event.shiftKey) {
    //         event.preventDefault();
    //         setEmoji(prev => (prev + '\n'))
    //     } else if (event.key === 'Enter' && event.shiftKey) {
    //         event.preventDefault();
    //         sendMessage(event as unknown as any);
    //     }
    // };

    const closeEmojiPicker = () => {
        showPicker && setShowPicker(false);
    }

    useEffect(() => {
        if (isOfferComment) {
            !!selectedChatId && MessageService.getCommentsMentionParticipants(selectedChatId, isContractComment).then(response => {
                setPossibleMentions(response?.data);
            }).catch(err => ErrorToast(err));
        } else {
            !!selectedChatId && MessageService.getMentionParticipants(selectedChatId).then(response => {
                setPossibleMentions(response?.data);
            }).catch(err => ErrorToast(err));
        }
    }, [selectedChatId]);


    useEffect(() => {
        // Bind the event listener
        document.body.addEventListener('click', closeEmojiPicker);
        return () => {
            // Unbind the event listener on clean up
            document.body.removeEventListener('click', closeEmojiPicker);
        };
    }, [showPicker]);

    return (
        <Form className="border-left border-top chat-input-height" onSubmit={(event) => sendMessage(event)}>
            <Row className="mx-0 w-100 align-items-center">
                <Col className={`py-2 pr-0 ${isChatBubble ? 'col-md-8 col-lg-8' : ''}`}>
                    <div className="px-2 px-md-0">
                        <MentionInputField value={emoji}
                                           possibleMentions={possibleMentions}
                                           changeMessageHandler={changeMessageHandler}
                                           sendMessage={sendMessage}/>
                    </div>
                </Col>
                <Col className={`px-md-0 ${isChatBubble ? 'col-md-4' : 'col-auto'}`}>
                    <div className="p-2 d-flex align-items-center justify-content-between">
                        <div>
                            <BsEmojiSmile className="chat-input-icon"
                                          tabIndex={1}
                                          onClick={() => {
                                              setShowPicker(prev => !prev);
                                          }}
                            />
                        </div>
                        {showPicker && (
                            <Picker
                                searchDisabled
                                style={{
                                    aspectRatio: '1/1',
                                    zIndex: 17,
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                }}
                                // pickerStyle={{width: '50%'}}
                                onEmojiClick={(em) => {
                                    onEmojiClick(em.emoji)
                                }}
                            />
                        )}
                        <div className="icon-upload mx-1">
                            <label htmlFor={`file-input-${selectedChatId}`} className="mb-0 position-relative">
                                <IoIosAttach className={`chat-input-icon ${!!newData?.file?.length ? 'haveFile' : ''}`}
                                />
                                {!!newData?.file?.length && <div className="notification-bullet-sm"/>}
                            </label>
                            <input id={`file-input-${selectedChatId}`}
                                   type="file"
                                   accept=".pdf,.doc,.docx,.txt,.xls,.xlsx,image/*"
                                   multiple
                                   name="file"
                                   onChange={(event) => changeFileHandler(event, setNewData)}/>
                        </div>
                        <i className="fi fi-rr-paper-plane d-flex chat-input-icon send"
                           onClick={(event: any) => sendMessage(event)}/>
                        {/*<MdSend className="chat-input-icon ml-2 send" onClick={(event) => sendMessage(event)}/>*/}
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
