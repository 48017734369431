import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyState from '../../../shared/components/placeholder/EmptyState';
import usePagination from '../../../shared/hooks/usePagination';
import {ErrorToast} from '../../../utils/toasters';
import {CampaignFilter} from '../campaign/component/CampaignFilter';
import {InfluencerCampaignListItem} from '../campaign/component/InfluencerCampaignListItem';
import {InfluencerCampaignsList} from '../campaign/component/InfluencerCampaignsList';
import {IMyCampaignItem} from '../campaign/dto/IMyCampaigns';
import {InfluencerMyCampaignsApi} from '../campaign/service';
import {AgencyService} from '../../agency/service';
import {AgencyCampaignListItem} from '../../agency/campaigns/components/AgencyCampaignListItem';
import {useHistory, useLocation} from 'react-router-dom';
import {InternalRoutes} from '../../../enum/InternalRoutes';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';

const DeclinedOffers = () => {
    const {filterParams, setTotalPages, setFilterParams, count, setCount} = usePagination({});
    const [declinedOffers, setDeclinedOffers] = useState<IMyCampaignItem[]>([]);
    const [longTermShow, setLongTermShow] = useState<boolean>(false);
    const location = useLocation();
    const isAgency = location.pathname.includes(InternalRoutes.AGENCY_DECLINED_OFFER);
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);

    const handlePerPage = (numberPerPage: number) => {
        setFilterParams((prev: any) => ({...prev, perPage: numberPerPage}));
    };

    useEffect(() => {
        isAgency ?
            AgencyService.getMyCampaignsDeclined({...filterParams, influencerId: selectedInfluencer?.id}).then(response => {
                setTotalPages(Math.ceil(response.count / response.perPage));
                setDeclinedOffers(response?.data);
                setCount(response.count);
                setLongTermShow(filterParams?.isContracts === true ? true : response?.info?.hasContracts);
            }).catch(err => ErrorToast(err))
            :
            InfluencerMyCampaignsApi.getMyCampaignsDeclined(filterParams).then(response => {
                setTotalPages(Math.ceil(response.count / response.perPage));
                setDeclinedOffers(response?.data);
                setCount(response.count);
                setLongTermShow(filterParams?.isContracts === true ? true : response?.info?.hasContracts);
            }).catch(err => ErrorToast(err));
    }, [JSON.stringify(filterParams), selectedInfluencer]);
    return (
        <Row>
            <Col xs={12} className="myCampaigns">
                <CampaignFilter 
                    filterParams={filterParams} 
                    setFilterParams={setFilterParams}
                    isDeclinedStatus={true}
                    showLongButton={longTermShow}
                    showButtonFilters
                />
                {!!declinedOffers?.length ?
                    <InfiniteScroll
                        dataLength={declinedOffers?.length}
                        next={() => {
                            handlePerPage(filterParams?.perPage + 15);
                        }}

                        endMessage={
                            <p className={`text-center ${(declinedOffers?.length !== count || filterParams?.perPage <= 10) ? 'd-none' : ''}`}>
                                <b>You have seen all!</b>
                            </p>
                        }
                        hasMore={declinedOffers?.length < count}
                        loader={<h4 className="text-center">Loading...</h4>}
                    >
                        <InfluencerCampaignsList isAgency={isAgency}>
                            <>
                                {declinedOffers?.map((campaign, index) => (
                                    isAgency ?
                                        <AgencyCampaignListItem key={`${index}, ${campaign.id}`} campaign={campaign}/> :
                                        <InfluencerCampaignListItem key={`${index}, ${campaign.id}`} campaign={campaign}/>
                                ))}
                            </>
                        </InfluencerCampaignsList>
                    </InfiniteScroll>
                    :
                    <div className="card mt-3">
                        <div className="card-body ">
                            <EmptyState/>
                        </div>
                    </div>}
            </Col>
        </Row>
    );
};

export default DeclinedOffers;
